<template>
  <div>
    <TextTitle titleName="个人信息"></TextTitle>
    <el-row>
      <el-col :span="6">
        <el-image :src="imgUrl" fit="cover">
          <div slot="error" class="image-slot">
            <i class="el-icon-document-delete"></i>
          </div>
        </el-image>
      </el-col>
      <el-col :span="16" :offset="2">
        <div class="menu-item" v-if="multipleSelection.length > 0">
          <div
            class="menu-box"
            v-for="(item, index) in multipleSelection"
            :key="item.context"
            @click="handleClick(item, index)"
          >
            <el-badge :value="item.value" class="item">
              <img src="../../assets/logo.png" />
            </el-badge>
            <p style="font-size: 12px; padding: 8px 0; text-align: center">
              {{ item.name }}
            </p>
          </div>
        </div>
        <div v-else class="menu-item" style="color: gray">暂未配置快捷权限</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        中文名：<span class="fontWeight">
          {{ name }}</span>
      </el-col>
      <el-col :span="11">
        <p>
          角色权限：<span class="fontWeight">{{userInfo.isadmin? (userInfo.roles[1] ? "超级管理员" : "管理员"):'普通人员' }}</span>
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        用户使用邮箱：<span class="fontWeight">
          {{ email }}
        </span>
      </el-col>
      <el-col :span="11">
        用户ID：<span class="fontWeight">
          {{ userId }}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        该用户的职位：<span class="fontWeight">
          {{ officium | filterRole}}</span>
      </el-col>
      <el-col :span="4">
        <el-button size="small" type="primary" @click="transferVisible = true"
          ><i class="el-icon-s-tools"></i>配置快捷权限</el-button
        >
      </el-col>
      <el-col :span="4">
        <el-button size="small" type="primary" @click="drawer = true"
          ><i class="el-icon-user-solid"></i>更新个人信息</el-button
        >
      </el-col>
    </el-row>
    <TextTitle titleName="我的背包"></TextTitle>
    <el-empty description="待更新......"></el-empty>
    <el-drawer title="更新信息" :visible.sync="drawer" direction="rtl">
      <div class="drawer-body">
        <el-row>
          <el-upload
            action
            :auto-upload="false"
            list-type="picture-card"
            :show-file-list="false"
            style="margin: 10px"
            :before-upload="beforeAvatarUpload"
            :on-change="transformBase"
            :on-success="uploadImg"
          >
            上传头像
          </el-upload>
        </el-row>
        <el-row type="flex" style="gap: 24px">
          <el-input
            v-model="ChinaName"
            placeholder="请输入中文名"
            maxlength="10"
            size="mini"
            show-word-limit
          ></el-input>
          <el-button @click="updateName" type="primary" size="mini"
            ><i class="el-icon-edit"></i>修改</el-button
          >
        </el-row>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="transferVisible" title="配置快捷权限">
      <curTransfer ref="curTransfer"></curTransfer>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="handleSaveShort"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateChinaName, enrollAdmin, uploadImg,getInfo } from "@/api/home";
import TextTitle from "@/components/TextTitle.vue";
import curTransfer from "./cusTransfer.vue";
export default {
  data() {
    return {
      transferVisible: false,
      multipleSelection: [],
      ChinaName: "",
      userName: "",
      password: "",
      chinaname: "",
      eMail: "",
      drawer: false,
      adminPassword: "",
      logoImg: "",
      //搜索
      isOperation: false, //是否是操作栏
    };
  },
  created() {
    this.multipleSelection = JSON.parse(localStorage.getItem("asgshortcut")) ?? [];
  },
  components: {
    curTransfer,
    TextTitle,
  },
  methods: {
    updateName() {
      updateChinaName(this.ChinaName)
        .then(() => {
          this.$message.success("修改成功！");
          this.initGetInfo();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    initGetInfo() {
      getInfo()
        .then((res) => {
          this.$store.commit("getUserInfo", res.data);
          sessionStorage.setItem("email", res.data.email);
          sessionStorage.setItem("baseImg", res.data.base64);
          sessionStorage.setItem("chinaname", res.data.chinaname);
          sessionStorage.setItem("officium", res.data.officium);
          sessionStorage.setItem("id", res.data.id);
          sessionStorage.setItem("isadmin", res.data.isadmin);
          sessionStorage.setItem("integral", res.data.integral);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beAdmin() {
      enrollAdmin(this.userName, this.password, this.ChinaName, this.eMail)
        .then(() => {
          this.$message.success("成功！");
          this.initGetUsers();
        })
        .catch((err) => {
          this.$message.error("失败" + err.message);
        });
    },
    //上传头像
    transformBase(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      const self = this;
      reader.onload = function () {
        // 转换完成输出该文件base64编码
        self.logoImg = reader.result;
        self.$store.commit("SET_LOGO", self.logoImg);
        self.uploadImg(self.logoImg);
      };
    },
    //上传请求
    uploadImg(logoImg) {
      uploadImg(logoImg)
        .then(() => {
          this.$message.success("更新成功！")
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //判断文件大小
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      console.log(isLt2M);
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleSaveShort() {
      this.transferVisible = false;
      this.multipleSelection = this.$refs.curTransfer.save();
    },
    handleClick(item) {
      switch (item.type) {
        case "1":
          this.$router.push("/index/schedule");
          break;
        case "2":
          this.$router.push("/index/news");
          break;
        case "3":
          this.$router.push("/index/blok");
          break;
        case "4":
          this.$router.push("/index/banpick");
          break;
        case "5":
          this.$router.push("/index/gamepic");
          break;
        case "6":
          this.$router.push("/index/ballot");
          break;
        default:
          this.$router.push("/");
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    name(){
      return this.userInfo.chinaname || sessionStorage?.getItem("chinaname");
    },
    email(){
      return this.userInfo.email || sessionStorage?.getItem("email");
    },
    userId(){
      return this.userInfo.id || sessionStorage?.getItem("id");
    },
    officium(){
      return this.userInfo.officium || sessionStorage?.getItem("officium");
    },
    imgUrl() {
      return (
        this.$store.state.userInfo.base64 || sessionStorage?.getItem("baseImg")
      );
    },
  },
};
</script>

<style scoped lang="less">
@btn-color: #53a0d4;

.updateInfo {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  overflow-y: scroll;
  max-height: 200px;
  width: 40%;
}
.el-card {
  margin: 0 20px;
  height: auto;
}
.el-select {
  width: 70%;
  margin-left: 10px;
  margin-bottom: 10px;
}

.el-image {
  margin-right: 10%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
/deep/.image-slot {
  color: #fff;
  background: red;
  text-align: center;
  line-height: 120px;
  font-size: 25px;
}
.userinfo {
  .name {
    width: 150px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 2em;
    margin-bottom: 10px;
  }
}

.login-info {
  p {
    line-height: 24px;
    font-size: 14px;
    color: #999;
    span {
      color: #666;
      margin-left: 60px;
    }
  }
}

.min-width-list {
  width: 300px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.box-card {
  .operation-btn {
    margin: 14px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-button {
      width: 60px;
    }
  }
  border: 1px solid #e7e7e7;
  padding: 20px;
  overflow: auto;
  height: 240px;
}

.dialog-footer {
  text-align: center;
}
.drawer-body{
  padding:12px;
}
.menu-item {
  display: flex;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  .menu-box {
    width: 60px;
    height: 60px;
    img {
      box-sizing: border-box;
      padding: 5px;
      border-radius: 10px;
      background-color: #eef7fe;
      display: block;
      width: 60px;
      height: 60px;
    }
  }
}
.el-col {
  margin: 12px;
}
.fontWeight{
  font-weight: bold;
}
</style>
