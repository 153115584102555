export const transferData = [
    {
        id:'1',
        name:'赛程安排',
        type:'1',
        value:'3'
    },
    {
        id:'2',
        name:'公告发布',
        type:'2',
        value:'10'
    },
    {
        id:'3',
        name:'冠军发布',
        type:'3',
        value:'6'
    },
    {
        id:'4',
        name:'合作伙伴',
        type:'4',
        value:'8'
    },
    {
        id:'5',
        name:'对战图制作',
        type:'5',
        value:'hot'
    },
    {
        id:'6',
        name:'抽签管理',
        type:'6',
        value:'1'
    },
]